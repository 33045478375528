import { Injectable } from '@angular/core';
import {
  doc,
  docData,
  DocumentReference,
  Firestore,
} from '@angular/fire/firestore';
import { HttpBackendService } from '@memberspot/frontend/shared/data-access/common';
import { BackendEndpoints } from '@memberspot/models';
import {
  BillingAddress,
  Customer,
  StripeAddCouponData,
  StripeUpdateAddressData,
} from '@memberspot/models';
import { UpdateVatIdDto } from '@memberspot/models';
import { CollectionConfig, CollectionService } from 'akita-ng-fire';
import { first } from 'rxjs';

import { CustomersStore, CustomerState } from './customers.store';

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: 'customers' })
export class CustomersService extends CollectionService<CustomerState> {
  constructor(
    protected override store: CustomersStore,
    private http: HttpBackendService,
    protected override db: Firestore,
  ) {
    super(store);
  }

  udpateActive(newStateOrCallback: Partial<Customer>) {
    this.store.updateActive(newStateOrCallback);
  }

  getCustomerFromFirestoreOnce(customerId: string) {
    return docData<Customer>(
      doc(this.db, `customers/${customerId}`) as DocumentReference<Customer>,
    ).pipe(first());
  }

  resetActive() {
    this.store.setActive(null);
  }

  updateAddress(schoolId: string, address: BillingAddress) {
    const data: StripeUpdateAddressData = {
      schoolId,
      address,
    };

    return this.http.put(BackendEndpoints.STRIPE.updateCustomerAddress(), data);
  }

  updateVatId(schoolId: string, vatId: string) {
    const data: UpdateVatIdDto = {
      schoolId,
      vatId,
    };

    return this.http.generic(
      BackendEndpoints.STRIPE.UPDATE_VATID(schoolId, data),
    );
  }

  checkVatId(schoolId: string, vatId: string) {
    const data: UpdateVatIdDto = {
      schoolId,
      vatId,
    };

    return this.http.generic(
      BackendEndpoints.STRIPE.CHECK_VATID(schoolId, data),
    );
  }

  addCoupon(schoolId: string, couponId: string) {
    const data: StripeAddCouponData = {
      schoolId,
      couponId,
    };

    return this.http.put(BackendEndpoints.STRIPE.addCoupon(), data);
  }
}
